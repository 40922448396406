import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-taz',
  templateUrl: './dialog-taz.component.html',
  styleUrls: ['./dialog-taz.component.css']
})
export class DialogTazComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogTazComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // console.log(this.data)
  }


  onClose() {
    this.dialogRef.close(false);
  }


}
