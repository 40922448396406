import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/api.service";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import jwtDecode from "jwt-decode";

@Component({
  selector: "app-verify-phone-otp",
  templateUrl: "./verify-phone-otp.component.html",
  styleUrls: ["./verify-phone-otp.component.css"],
})
export class VerifyPhoneOtpComponent implements OnInit, OnDestroy {
  form: FormControl;
  otp: string = "";
  isLoading: boolean = false;
  private countdownTimer: ReturnType<typeof setTimeout>;
  public counter: number = 600;
  registerData: any;
  tokenData: any;
  tokenLogin: any;
  constructor(private router: Router, private service: ApiService) {
    this.form = new FormControl("", [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]);
  }

  @ViewChild("panel") public panel: ElementRef;
  ngOnInit() {
    // INITIATION
    const storedData2 = localStorage.getItem("signUpData");
    // console.log(storedData2)
    this.tokenData = storedData2 ? JSON.parse(storedData2) : [];
    console.log(this.tokenData)

    setTimeout(() => {
      this.panel.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 300);


    this.startCountdown();
  }

  ngOnDestroy() {
    this.clearCountdown();
  }

  resendOtp() {
    // Add logic here to resend OTP
    console.log("resend OTP");
    const token = localStorage.getItem('tokenDaftar');

    this.service.register(this.tokenData).subscribe(
      (data) => {
        const token = data.token;
        const expired = data.exp;
        const cleanedToken = token.replace(/"/g, "");

        console.log(expired);
        localStorage.setItem("tokenDaftar", cleanedToken);
        localStorage.setItem("expired", JSON.stringify(expired));
        this.counter = 600;
        this.startCountdown()
      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: `${error.error.message}!`,
        });
      }
    );
  }

  submitOtp() {
    // data parameter untuk submit OTP
    const token = localStorage.getItem("tokenDaftar");
    const params = {
      otp: this.form.value,
      options: {
        native: true,
      },
    };
    this.isLoading = true
    this.service.verifyOTP(JSON.stringify(params), token).subscribe(
      (response) => {
        // console.log(response)
        this.tokenLogin = response.token;
        localStorage.setItem("jwt", this.tokenLogin);
        const decodedToken: any = jwtDecode(this.tokenLogin);
        localStorage.setItem("username", decodedToken.user);
        localStorage.removeItem('expired')

        // console.log(this.tokenLogin);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Verifikasi berhasil",
        })
        this.service.verifyUserExist(this.tokenLogin).subscribe(
          () => {
            this.service.activateStore(this.tokenLogin).subscribe((data) => {
              setTimeout(() => {
                // this.isLoading = false
                this.router.navigate(["register"]);
              }, 1500);
            });
          },
          (err) => {
            this.isLoading = false
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.error.message,
            });
          }
        );




      },
      (error) => {
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: `${error.error.message}!`,
        });
      }
    );
  }

  startCountdown() {
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const expTimestamp = parseInt(localStorage.getItem('expired'))
    // const expTimestamp = Math.floor(expDate / 1000)

    console.log(expTimestamp, currentTimestamp)
    const remainingTime = expTimestamp - currentTimestamp;
    this.counter = Math.floor(remainingTime);
    console.log("remaining time:" + remainingTime);

    this.countdownTimer = setTimeout(() => {
      const minutes = Math.floor(this.counter / 60);
      const seconds = this.counter % 60;
      const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      console.log(formattedTime)

      // this.counter--;
      if (this.counter === 0) {
        this.clearCountdown();
        document.getElementById("resendButton").removeAttribute("disabled");
        document.getElementById("counter").style.display = "none";
      } else {
        document.getElementById(
          "counter"
        ).textContent = `Kirim ulang OTP dalam ${formattedTime}`;
        this.startCountdown();
      }
    }, 1000);
  }

  clearCountdown() {
    clearTimeout(this.countdownTimer);
  }
  closeOtpContainer(): void {
    // Add logic here to close the OTP container
    setTimeout(() => {
      this.router.navigate(["signup"]);
    }, 1500);
  }

  onInputChange(event: any) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    event.target.value = inputValue;
  }
}
