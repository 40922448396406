import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import PerfectScrollbar from "perfect-scrollbar";
import { ApiService } from "../api.service";
import { clearLocalStorage } from "../utility.service";
import { throwError } from "rxjs";
import { timeout, catchError } from "rxjs/operators";

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  // {
  //     path: '/dashboard',
  //     title: 'Dashboard',
  //     type: 'link',
  //     icontype: 'dashboard'
  // },
  {
    path: "/products",
    title: "Produk",
    type: "sub",
    icontype: "inventory_2",
    collapse: "products",
    children: [
      { path: "add-product", title: "Tambah Barang", ab: "add_shopping_cart" },
      { path: "list-product", title: "Lihat Semua Barang", ab: "ballot" },
    ],
  },
  {
    path: "/store-transactions",
    title: "Transaksi",
    type: "link",
    icontype: "receipt_long",
  },
  {
    path: "/pos-transactions",
    title: "POS Order",
    type: "sub",
    icontype: "shopping_bag",
    collapse: "shopping_bag",
    children: [
      {
        path: "list-product",
        title: "List Produk",
        ab: "list_alt",
      },
      {
        path: "review-order",
        title: "Transaksi Order",
        ab: "shopping_cart",
      },
    ],
  },
  // {
  //   path: "/vouchers",
  //   title: "Voucher",
  //   type: "sub",
  //   icontype: "confirmation_number",
  //   collapse: "vouchers",
  //   children: [
  //     { path: "add-voucher", title: "Tambah Voucher", ab: "library_add" },
  //     { path: "list-voucher", title: "Lihat Semua Voucher", ab: "ballot" },
  //   ],
  // },
  {
    path: "/store",
    title: "Toko",
    type: "sub",
    icontype: "store",
    collapse: "store",
    children: [
      { path: "store-homepage", title: "Beranda Toko", ab: "home" },
      { path: "store-profile", title: "Profil Toko", ab: "storefront" },
      { path: "store-category", title: "Etalase Toko", ab: "category" },
      {
        path: "store-balance",
        title: "Penghasilan Toko",
        ab: "account_balance_wallet",
      },
      { path: "store-setting", title: "Pengaturan Toko", ab: "storefront" },
    ],
  },
  {
    path: "/chat",
    title: "Chat",
    type: "link",
    icontype: "chat",
    // children: [
    //   { path: "store-profile", title: "Profil Toko", ab: "storefront" },
    //   { path: "store-category", title: "Etalase Toko", ab: "category" },
    //   {
    //     path: "store-balance",
    //     title: "Penghasilan Toko",
    //     ab: "account_balance_wallet",
    //   },
    //   { path: "store-setting", title: "Pengaturan Toko", ab: "storefront" },
    // ],
  },
  {
    path: "/setting",
    title: "Pengaturan",
    type: "sub",
    icontype: "settings",
    collapse: "setting",
    children: [{ path: "account", title: "Akun", ab: "people_alt" }],
  },
];

@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  storeInfo: any;
  imageAvailable: boolean = false;
  nameAvailable: boolean = false;
  intervalId: any;

  //Login verify
  constructor(
    private routes: Router,
    private service: ApiService,
    private router: Router
  ) {
    this.service.getStoreId().subscribe((data) => {
      // console.log("Store Id: " + data);
      this.service.storeInfo(data.store_id).subscribe((store_info) => {
        // console.log("Store Info: " + store_info);
        this.storeInfo = store_info;

        if (store_info.registration_step) {
          this.routes.navigate(["/register"]);
        } else {
          // console.log("works");
        }

        if (
          store_info.image_url !== null ||
          store_info.image_url !== undefined ||
          !store_info.image_url.endsWith("null")
        ) {
          this.imageAvailable = true;
        }

        if (store_info.name !== null || store_info.name !== undefined) {
          this.nameAvailable = true;
        }
      });
    });
  }

  loggedin: boolean = false;
  loggedinUser: string;

  public menuItems: any[];
  ps: any;
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
    if (
      localStorage.getItem("username") != null &&
      localStorage.getItem("jwt") != null
    ) {
      this.loggedinUser = localStorage.getItem("username");
      this.loggedin = true;
    } else {
      clearLocalStorage();
    }
    this.updateLastOnline();
    this.intervalId = setInterval(() => {
      this.updateLastOnline();
    }, 60000);
  }

  cancelLastOnlineTimeout() {
    clearInterval(this.intervalId);
  }
  updateLastOnline() {
    if (!localStorage.getItem("jwt")) this.cancelLastOnlineTimeout();
    this.service
      .lastOnlineStore()
      .pipe(
        timeout(1000), // Set a timeout of 5 seconds (adjust as needed)
        catchError((error) => {
          if (error.name === "TimeoutError") {
            // console.error('API request timed out.');
          }
          return "";
        })
      )
      .subscribe(() => {
        // console.log("Last online updated");
      });
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  logout() {
    // console.log(localStorage.getItem('jwt'));

    clearLocalStorage();
    this.loggedin = false;
    this.router.navigate([""]);
  }
}
