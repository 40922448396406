import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { NumberFormatPipe } from "./number.pipe";

@Directive({ selector: "[NumberFormatter]" })
export class NumberFormatterDirective implements OnInit {

    private el: HTMLInputElement;

    constructor(
        private elementRef: ElementRef,
        private numberPipe: NumberFormatPipe
    ) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
        this.el.value = 'Rp' + this.numberPipe.transform(this.el.value);
    }

    @HostListener("focus", ["$event.target.value"])
    onFocus(value) {
        value = value.replace(/[^\d]/g, '');
        value = value.replace(/[^0-9.]/g, '');
        this.el.value = value;
    }

    @HostListener("blur", ["$event.target.value"])
    onBlur(value) {
        this.el.value = 'Rp' + this.numberPipe.transform(value);
    }

}