import { Routes } from "@angular/router";
import { AuthGuard, StoreExist } from "./auth.guard";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { MiscLayoutModule } from "./misc-layout/misc-layout.module";
import { VerifyPhoneOtpComponent } from "./pages/verify-phone-otp/verify-phone-otp.component";
import { RegisterStoreComponent } from "./register-store/register-store.component";
import { ChatComponent } from "./chat-store/chat/chat.component";

export const AppRoutes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, StoreExist],
    // runGuardsAndResolvers: 'always',
    children: [
      {
        path: "",
        redirectTo: "/login",
        pathMatch: "full",
      },
      // {
      //   path: "dashboard",
      //   loadChildren: () =>
      //     import("./dashboard/dashboard.module").then((x) => x.DashboardModule),
      // },
      {
        path: "forms",
        loadChildren: () => import("./forms/forms.module").then((x) => x.Forms),
      },
      {
        path: "products",
        loadChildren: () =>
          import("./products/products.module").then((x) => x.ProductsModule),
      },
      {
        path: "store-transactions",
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (x) => x.TransactionsModule
          ),
      },
      {
        path: "pos-transactions",
        loadChildren: () =>
          import("./pos-transaction/pos-transaction.module").then(
            (x) => x.PosTransactionModule
          ),
      },
      {
        path: "store",
        loadChildren: () =>
          import("./store/store.module").then((x) => x.StoreModule),
      },
      {
        path: "statistics",
        loadChildren: () =>
          import("./statistics/statistics.module").then(
            (x) => x.StatisticsModule
          ),
      },
      {
        path: "setting",
        loadChildren: () =>
          import("./setting/setting.module").then((x) => x.SettingModule),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./calendar/calendar.module").then((x) => x.CalendarModule),
      },
      {
        path: "vouchers",
        loadChildren: () =>
          import("./vouchers/vouchers.module").then((x) => x.VouchersModule),
      },
      {
        path: "chat",
        loadChildren: () => import('./chat-store/chat-store.module').then((x) => x.ChatStoreModule)
      }
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./misc-layout/misc-layout.module").then(
            (x) => x.MiscLayoutModule
          ),
      },
    ],
  },
  {
    path: "register",
    canActivate: [AuthGuard],
    component: RegisterStoreComponent
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/pages.module").then((x) => x.PagesModule),
      },
    ],
  },
  {
    path: "signup/otp",
    component: VerifyPhoneOtpComponent,
  },
  {
    path: "**",
    redirectTo: "/store-transactions",
    pathMatch: "full",
  },
];
