import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MessageCornerComponent } from './message-corner.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule,} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
    imports: [ 
        RouterModule, 
        CommonModule,
        MatMenuModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [ MessageCornerComponent ],
    exports: [ MessageCornerComponent ]
})

export class MessageCornerModule {}
