export const messages = [{
    from: 'Deka Furniture',
    photo: 'assets/img/user-avatar.png',
    lastdate: new Date('2016-01-05'),
    chat: [
        {
            type: 'odd',
            msg: 'Terimakasih sudah mengunjungi toko kami. Nikmati belanja dengan harga murah dan pengiriman keseluruh Indonesia',
            date: new Date('2016-01-05')
        },
        {
            type: 'odd',
            msg: 'Jangan lupa pakai kupon mu untuk mendapatkan diskon', 
            date: new Date('2016-01-06')
        },
        {
            type: 'even',
            msg: 'pengiriman ke Bandung bisa pakai ekspedisi apa ya Kak?',
            date: new Date('2016-01-07')
        },
        {
            type: 'odd',
            msg: 'Untuk ekspedisi dapat dipilih sesuai keinginan kaka pada saat check out, kita menyediakan beberapa ekspedisi',
            date: new Date('2016-01-08')
        },
        {
            type: 'even',
            msg: 'Kalau saya mau pake On Delivery bisa Kak?',
            date: new Date('2016-01-09')
        },
        {
            type: 'odd',
            msg: 'Bisa Kak, silahkan dipilih pada menu ekspedisi ya',
            date: new Date('2016-01-10')
        }
    ]
}, {
    from: 'Bella Fashion',
    photo: 'assets/img/user-avatar.png',
    lastdate: new Date('2016-01-05'),
    chat: [
        {
            type: 'even',
            msg: 'Halo Kak, untuk ukuran sepatunya bagaimana ya?',
            date: new Date('2016-01-10')
        },
        {
            type: 'odd',
            msg: 'Halo Kak, ukuran sepatu bisa dilihat pada petunjuk disetiap foto ya Kak. usahakan mengukur dengan benar agak tidak kebesaran atau kekecilan',
            date: new Date('2016-01-08')
        },
        {
            type: 'even',
            msg: 'untuk lebarnya standar atau lebih kecil ya?',
            date: new Date('2016-01-09')
        },
        {
            type: 'odd',
            msg: 'standar Kak untuk ukurannya, silahkan dilihat pada bagian poto produk',
            date: new Date('2016-01-10')
        }
    ]
}, {
    from: 'Toko Mainan',
    photo: 'assets/img/user-avatar.png',
    lastdate: new Date('2016-01-05'),
    chat: [
        {
            type: 'odd',
            msg: 'Halo Kak.',
            date: new Date('2016-01-05')
        },
        {
            type: 'odd',
            msg: 'Seluruh Produk Kami masih diskon hingga 30 April, jangan sampai kelewatan ya :)',
            date: new Date('2016-01-06')
        },
        {
            type: 'even',
            msg: 'Lego yang series Iron Man masi ada ga? ',
            date: new Date('2016-01-07')
        },
        {
            type: 'odd',
            msg: 'Ada Kak, silahkan lihat di bagian produk terbaru',
            date: new Date('2016-01-08')
        },
        {
            type: 'even',
            msg: 'Baik terimakasih Kak',
            date: new Date('2016-01-09')
        },
        {
            type: 'odd',
            msg: 'Sama-sama Kak, ditunggu check outnya ',
            date: new Date('2016-01-10')
        }
    ]
}, {
    from: 'Sport Station',
    photo: 'assets/img/user-avatar.png',
    lastdate: new Date('2016-01-05'),
    chat: [
        {
            type: 'even',
            msg: 'Halo Kak, kalau check out hari ini, bisa dikirim  hari ini juga ga?',
            date: new Date('2016-01-10')
        },
        {
            type: 'odd',
            msg: 'Pembayaran sebelum jam 12 akan dikirim hari itu juga Kak',
            date: new Date('2016-01-10')
        },
        {
            type: 'even',
            msg: 'Oke Kak, aku check out sekarang. semoga bisa cepat sampai',
            date: new Date('2016-01-10')
        },
        {
            type: 'odd',
            msg: 'Baik Kak, ditunggu pesanannya',
            date: new Date('2016-01-10')
        },
        {
            type: 'even',
            msg: 'siap',
            date: new Date('2016-01-10')
        }
    ]
}];
