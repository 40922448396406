import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { messages } from './chat-data';


@Component({
  selector: 'app-message-corner',
  templateUrl: './message-corner.component.html',
  styleUrls: ['./message-corner.component.css']
})
export class MessageCornerComponent{

    myList: any;
    sidePanelOpened = true;
    msg = '';

    // MESSAGE
    selectedMessage: any;
    messages: Object[] = messages;

    constructor() {
        this.selectedMessage = this.messages[0];
    }

    @ViewChild('myInput', { static: true }) myInput: ElementRef = Object.create(null); 
 
    isOver(): boolean {
        return window.matchMedia(`(max-width: 960px)`).matches;
    }

    onSelect(message: Object[]): void {
        this.selectedMessage = message;
    }

    OnAddMsg() {
        this.msg = this.myInput.nativeElement.value;

        if (this.msg !== '') {
            this.selectedMessage.chat.push({
                type: 'even',
                msg: this.msg,
                date: new Date()
            });
        }

        this.myInput.nativeElement.value = '';
    }

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    closeMyMenu() {
        this.trigger.closeMenu();
    }  
}
