import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import Swal from 'sweetalert2';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private apiService:ApiService, 
    private routes: Router) { 
    }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    if (localStorage.getItem('jwt') != null) {
      try{
        await this.apiService.verify(localStorage.getItem('jwt')).toPromise()
        return true
      }catch(err) {
        console.log(err)
        return false;
      }
    } 
    this.routes.navigate(['/']);
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})

export class StoreExist implements CanActivate {

  constructor(
    private apiService:ApiService, 
    private routes: Router) { 
    }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    try{
      await this.apiService.verifyStoreExist(localStorage.getItem('jwt')).toPromise()
      return true
    }catch(err) {
      console.log(err)
      // this.routes.navigate(['/activation-store'])
      return false
    }
    
  }
}