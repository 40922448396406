import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddressData } from "./store/store-setting/address/address.component";
import { BankData, GetBank } from "./store/store-setting/bank/bank";
import { updateShippingMethod } from "./store/store-setting/services/services.component";
import { Withdraw, exportMutation, getMutation } from "./models/balance";
import { Schedule } from "./models/store-info";
import { AddProduct, FetchProduct } from "./models/product";
import { Checkout, GetPOSHistory } from "./models/pos-order";

@Injectable()
export class ApiService {
  //DEMO
  // API_OSAS_SERVER = "https://testapi.ondelivery.id/";
  // API_USERSTATE_SERVER = "https://demousapi.onindonesia.id/";
  // API_USERONDELIVERY_SERVER = "http://202.157.187.188:3400/";
  // API_ONMARKET_SERVER = "https://testapi.onmarket.id/";
  // // API_ONMARKET_SERVER = "http://localhost:3610/";
  // API_ONBOOKING_SERVER = "https://demoapisat.ondelivery.id/";
  // ONMARKET_WEBSITE = "https://test.onmarket.id";
  
  //PROD
  ONMARKET_WEBSITE = "https://onmarket.id"
  API_OSAS_SERVER = "https://api.ondelivery.id/";
  API_USERSTATE_SERVER = "https://usapi.onindonesia.id/";
  API_USERONDELIVERY_SERVER = "http://202.157.187.188:3400/";
  API_ONMARKET_SERVER = "https://api.onmarket.id/";
  API_ONBOOKING_SERVER = "https://apisat.ondelivery.id/";

  constructor(private http: HttpClient) { }

  /** --------------------------------- */
  /**       START - USERSTATE API      **/
  /** --------------------------------- */

  getUser(uname: string, pwd: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      username: uname,
      password: pwd,
      options: {
        nativeApp: false,
      },
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "auth",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  register(content: any) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "signup",
      JSON.stringify(content),
      { headers: customHeaders }
    );
  }

  resendOTP(token: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(
      this.API_USERSTATE_SERVER + "resend-otp-register",
      {
        headers: customHeaders,
      }
    );
  }

  verifyOTP(params: any, token: any): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "verify-sign-up",
      params,
      {
        headers: customHeaders,
      }
    );
  }

  verify(token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify", {
      headers: customHeaders,
    });
  }

  verifySignUp(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify-sign-up", {
      headers: customHeaders,
    });
  }

  logOff(token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "logoff", {
      headers: customHeaders,
    });
  }

  requestResetPwd(email: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const payload = {
      email: email,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "request-reset-pwd",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  verifyLinkResetPwd(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "verify-reset-pwd", {
      headers: customHeaders,
    });
  }

  resetPwd(password: string, token: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    });
    const payload = {
      password: password,
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "reset-pwd",
      JSON.stringify(payload),
      { headers: customHeaders }
    );
  }

  updateBasicProfile(payload: FormData, token: string) {
    let customHeaders = new HttpHeaders({
      authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "update-basic-profile",
      payload,
      { headers: customHeaders }
    );
  }

  retrieveBasicProfile() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "basic-profile", {
      headers: customHeaders,
    });
  }

  getBalance() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_USERSTATE_SERVER + "balance", {
      headers: customHeaders,
    });
  }

  /** ------------------------------- */
  /**       END - USERSTATE API      **/
  /** ------------------------------- */

  /** --------------------------------- */
  /**       START - ONMARKET API       **/
  /** --------------------------------- */

  verifyUserExist(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "verify-user-exist", {
      headers: customHeaders,
    });
  }

  verifyStoreExist(token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "verify/store/exist", {
      headers: customHeaders,
    });
  }

  activateStore(tokenLogin: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + tokenLogin,
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/activate", {
      headers: customHeaders,
    });
  }

  // ----- STORE API ----- //

  editStore(payload: FormData, token: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "store/edit",
      payload,
      { headers: customHeaders }
    );
  }

  storeInfo(store_id: string) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    // console.log(store_id);
    const params = new HttpParams()
      .set("store_id", store_id)
      .set("type", "STORE");
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/detail", {
      headers: customHeaders,
      params: params,
    });
  }

  updateRegistrationStep(step: number) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });

    return this.http.patch(
      this.API_ONMARKET_SERVER + "store/reg/change-step",
      { step: step },
      { headers: customHeaders }
    );
  }

  getStoreId() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "store/store-id", {
      headers: customHeaders,
    });
  }

  storeActivity(status: boolean) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    // console.log(status);
    const payload = {
      status: status,
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "store/activity/edit",
      payload,
      { headers: customHeaders }
    );
  }

  lastOnlineStore() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "store/check-online-status",
      { headers: customHeaders }
    );
  }

  // ----- STORE CATEGORY API ----- //

  listStoreCategory(content: number) {
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/list/category"
    );
  }

  listStoreCategorySetting() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const payload = {
      store_id: localStorage.getItem("storeId"),
    };
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/list/category/setting",
      {
        headers: customHeaders,
        params: payload,
      }
    );
  }

  listCategoryAll() {
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "product/list/category-tree"
    );
  }

  addStoreCategory(name: string, parent_id: number, token: string) {
    let customHeaders = new HttpHeaders({
      authorization: "Bearer " + token,
    });
    const body = {
      name: name,
      parent_id: Number(parent_id),
      store_id: Number(localStorage.getItem("storeId")),
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/category/add",
      body,
      { headers: customHeaders }
    );
  }

  editStoreCategory(
    name: string,
    parent_id: number,
    category_id: number,
    token: string
  ) {
    let customHeaders = new HttpHeaders({
      authorization: "Bearer " + token,
    });
    const body = {
      name: name,
      parent_id: Number(parent_id) ? Number(parent_id) : null,
      category_id: Number(category_id),
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/category/edit",
      body,
      { headers: customHeaders }
    );
  }

  deleteStoreCategory(payload, token: string) {
    let customHeaders = new HttpHeaders({
      authorization: "Bearer " + token,
    });
    const body = {
      category_id: Number(payload.category_id),
    };
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "product/category/delete",
      body,
      { headers: customHeaders }
    );
  }

  // addProductCategory(content) {
  //   let customHeaders = new HttpHeaders({
  //     'authorization': 'Bearer ' + localStorage.getItem("jwt"),
  //   });
  //   return this.http.post<any>(this.API_ONMARKET_SERVER + "store/front/list/add", content, { headers: customHeaders })
  // };

  // deleteProductCategory(front_list_id) {
  //   let customHeaders = new HttpHeaders({
  //     'authorization': 'Bearer ' + localStorage.getItem("jwt"),
  //   });
  //   const payload = {
  //     front_list_id: front_list_id.store_front_lists[0].front_list_id
  //   }

  //   return this.http.post<any>(this.API_ONMARKET_SERVER + "store/front/list/delete", payload, { headers: customHeaders })
  // };

  // ----- ADDRESS API ----- //

  destinationSearch(content: string): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // authorization: localStorage.getItem("jwt")!,
    });
    const body = {
      search_dest: content,
    };
    // console.log(body);
    return this.http.get<any>(
      this.API_OSAS_SERVER + "/check-fee/destination/searchlanding",
      {
        headers: customHeaders,
        params: body,
      }
    );
  }

  addAddress(payload: AddressData) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    // console.log("payload");
    return this.http.put<any>(
      this.API_ONMARKET_SERVER + "store/address",
      payload,
      { headers: customHeaders }
    );
  }

  editAddress(payload: any) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/edit",
      payload,
      { headers: customHeaders }
    );
  }

  deleteAddress(payload: any) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "user/address/delete",
      payload,
      { headers: customHeaders }
    );
  }

  listAddress() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "store/address",

      {
        headers: customHeaders,
      }
    );
  }

  viewAddreessDetail() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "user/address/detail",
      { headers: customHeaders }
    );
  }

  listMainAddress() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "user/address/main", {
      headers: customHeaders,
    });
  }

  userAddressDetail(address_id: number) {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "user/address/detail/" + address_id,
      params
    );
  }

  // NOTE: BANK API
  getListBank(value): Observable<any> {
    const payload: GetBank = {
      bank_name: value,
      channel_type: "Bank",
    };
    return this.http.post<any>(
      this.API_USERSTATE_SERVER + "bank/list",
      payload
    );
  }

  getBankAccount() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "bank", {
      headers: customHeaders,
    });
  }

  addBankAccount(content) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post<any>(this.API_ONMARKET_SERVER + "bank", content, {
      headers: customHeaders,
    });
  }

  editBankAccount(content) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.put<any>(this.API_ONMARKET_SERVER + "bank", content, {
      headers: customHeaders,
    });
  }

  deleteBankAccount(id: number) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.delete<any>(this.API_ONMARKET_SERVER + "bank?id=" + id, {
      headers: customHeaders,
    });
  }

  // NOTE : expedition API

  expeditionList() {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.get<any>(this.API_ONMARKET_SERVER + "expedition/setting", {
      headers: customHeaders,
    });
  }

  updateExpedition(payload: updateShippingMethod[]) {
    let customHeaders = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.put<any>(
      this.API_ONMARKET_SERVER + "expedition/setting",
      payload,
      { headers: customHeaders }
    );
  }

  tracking(invoice: string) {
    const accessToken = localStorage.getItem("jwt");
    const params = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        invoiceNumber: invoice,
      },
    };
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "transaction/order/monitor-delivery",
      params
    );
  }

  invoiceDetail(invoice: string): Observable<any> {
    const accessToken = localStorage.getItem("jwt");
    const params = accessToken
      ? {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          invoice: invoice,
        },
      }
      : {};
    return this.http.get<any>(
      this.API_ONMARKET_SERVER + "transaction/invoice/detail",
      params
    );
  }

  waybillBasic(waybill: Array<string>) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // 'authorization': localStorage.getItem("jwt")!,
    });
    const payload = {
      searchMethod: "trackingID",
      searchTrackID: waybill,
    };
    // return this.http.post<any>(this.API_USERONDELIVERY_SERVER+"waybill-basic", JSON.stringify(payload), {headers: customHeaders})
    return this.http.post<any>(
      this.API_OSAS_SERVER + "/waybill/tracking-scan",
      payload,
      { headers: customHeaders }
    );
  }

  alltimeBalance(startDate: string = "", endDate: string = "") {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    const params = new HttpParams()
      .set("start_date", startDate)
      .set("end_date", endDate)

    return this.http.get(
      this.API_ONMARKET_SERVER + "balance/v1/alltime-balance",
      {
        headers: customHeaders,
        params
      }
    );
  }

  fetchMutation(payload: getMutation) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });

    return this.http.post(
      this.API_ONMARKET_SERVER + "balance/v1/mutation",
      payload,
      { headers: customHeaders }
    );
  }

  exportMutation(payload: exportMutation): Observable<any> {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });

    return this.http.post<any>(
      this.API_ONMARKET_SERVER + "balance/v1/mutation/download",
      payload,
      { headers: customHeaders, observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  withdraw(payload: Withdraw) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(
      this.API_ONMARKET_SERVER + "balance/v1/withdraw",
      payload,
      { headers: customHeaders }
    );
  }

  updateSchedule(payload: Schedule) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(
      this.API_ONMARKET_SERVER + "store/schedule/edit",
      payload,
      { headers: customHeaders }
    );
  }

  addPOSProduct(payload: any) {
    let customHeaders = new HttpHeaders({
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(
      this.API_ONMARKET_SERVER + "product/pos/add",
      payload,
      { headers: customHeaders }
    );
  }

  updatePOSProduct(payload: any) {
    let customHeaders = new HttpHeaders({
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(
      this.API_ONMARKET_SERVER + "product/pos/edit",
      payload,
      { headers: customHeaders }
    );
  }

  fetchProduct(payload: FetchProduct) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(
      this.API_ONMARKET_SERVER + "product/pos/list",
      payload,
      { headers: customHeaders }
    );
  }

  reviewPOSOrder(payload) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(
      this.API_ONMARKET_SERVER + "transaction/pos/review",
      payload,
      { headers: customHeaders }
    );
  }

  checkoutPOSOrder(payload: Checkout) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(
      this.API_ONMARKET_SERVER + "transaction/pos/checkout",
      payload,
      { headers: customHeaders }
    );
  }

  fetchPOSHistory(payload: GetPOSHistory) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });
    return this.http.post(
      this.API_ONMARKET_SERVER + "transaction/list/pos",
      payload,
      { headers: customHeaders }
    );
  }

  POSEwalletCheckStatus(payment_id: string) {
    let customHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      authorization: "Bearer " + localStorage.getItem("jwt"),
    });

    const payload = {
      payment_id: payment_id
    }
    return this.http.post(
      this.API_ONMARKET_SERVER + "transaction/payment/status",
      payload,
      { headers: customHeaders }
    );
  }


}
