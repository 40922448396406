import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Schedule, StoreInfo } from '../models/store-info';
import { ApiService } from 'src/app/api.service';
import { clearLocalStorage } from 'src/app/utility.service';
import { StoreAddress, addAddress } from '../models/store-address';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { HttpParams } from '@angular/common/http';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AddressData } from '../store/store-setting/address/address.component';
import { shippingMethod, updateShippingMethod } from '../store/store-setting/services/services.component';
import { Loader } from "@googlemaps/js-api-loader";

@Component({
  selector: 'app-register-store',
  templateUrl: './register-store.component.html',
  styleUrls: ['./register-store.component.css']
})
export class RegisterStoreComponent implements OnInit {

  isCollapsed: boolean = true;
  loggedin: boolean = false;
  loggedinUser: string;
  isLoading: boolean = true

  //info
  storeInfo: StoreInfo
  imageUrl: string;
  storeInfoData = new FormData();
  storeInfoForm: FormGroup
  onmarketWebsite = ""
  submitLoading = false

  storeSchedule: Schedule = { "friday": { "open": "00:00:00", "close": "23:59:00" }, "monday": { "open": "00:00:00", "close": "23:59:00" }, "sunday": { "open": "00:00:00", "close": "23:59:00" }, "tuesday": { "open": "00:00:00", "close": "23:59:00" }, "saturday": { "open": "00:00:00", "close": "23:59:00" }, "thursday": { "open": "00:00:00", "close": "23:59:00" }, "wednesday": { "open": "00:00:00", "close": "23:59:00" } }

  //address
  storeAddress: StoreAddress
  addressForm: FormGroup
  submitAddressLoading = false
  destinationSearchData: any = []
  MAPS_API_KEY = "AIzaSyC0lglmhI4XgQx_oeqTYKC--LrOgzXQRNk"
  private map: google.maps.Map
  debounce_lat: number;
  debounce_lng: number;
  temp_location: any;

  //expedition
  submitExpeditionLoading = false

  //stepper
  currentStep: number = 1;
  address: any;
  expeditionData: shippingMethod[];
  shippingForm: any;


  constructor(private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder) {
    this.storeInfoForm = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      image_url: [null],
      store_domain: [null, Validators.compose([Validators.required])],
    });

    this.addressForm = this.fb.group({
      recipient: [''],
      dest: ["", Validators.compose([Validators.required])],
      phone: ['', Validators.compose([
        Validators.pattern("^628[1-9][0-9]{6,13}$"),
        Validators.required,
      ]),],
      address_input: ['', Validators.compose([Validators.required])],
      lat: [
        null,
        Validators.compose([Validators.required]),
      ],
      long: [
        null,
        Validators.compose([Validators.required]),
      ],

    })
  }

  ngOnInit(): void {

    this.fetchStoreInfo(true)
    this.fetchStoreAddress()

    if (
      localStorage.getItem("username") != null &&
      localStorage.getItem("jwt") != null
    ) {
      this.loggedinUser = localStorage.getItem("username");
      this.loggedin = true;

      this.apiService.verify(localStorage.getItem("jwt")).subscribe(
        (r) => {
          // console.log(localStorage.getItem('username'))
          this.loggedinUser = localStorage.getItem("username");
          // this.router.navigate(['/dashboard'])
        },
        (e) => {
          clearLocalStorage();
        }
      );
    } else {
      clearLocalStorage();
    }

    this.destinationSearchData = this.addressForm.get(
      "dest"
    )!.valueChanges.pipe(
      tap((value) => console.log("Value Changes:", value)),
      distinctUntilChanged(),
      debounceTime(300),
      startWith(""),
      switchMap((value) => this.destination_serv(value))
    );



  }

  fetchStoreAddress() {
    this.isLoading = true
    this.apiService.listAddress().subscribe(
      (data: StoreAddress) => {
        // console.log(data);
        this.storeAddress = data;
        this.address = data.address
        let dest = `${data.address?.urban_name}, ${data.address?.sub_district_name}, ${data.address.city_name}, Prov. ${data.address?.province_name}, ${data.address?.postal_code}`
        this.addressForm.get('recipient').setValue(data.recipient)
        this.addressForm.get('recipient').disable()
        this.addressForm.get('dest').setValue(dest)
        this.addressForm.get('phone').setValue(data.phone)
        this.addressForm.get('address_input').setValue(data.address_input)
        this.addressForm.get('lat').setValue(data.lat)
        this.addressForm.get('long').setValue(data.long)

        if (data.lat && data.long)
          this.updateMap({ location: { lat: Number(data.lat), lng: Number(data.long) } });

        this.isLoading = false
      },
      e => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            console.log(lat, lng)
            this.updateMap({ location: { lat: lat, lng: lng } });
          }, (error) => {
            console.error('Error getting location', error);
          });
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
        console.error(e)
        this.isLoading = false
      }
    )

  }

  fetchStoreInfo(intiial: boolean) {
    this.isLoading = true
    this.apiService.getStoreId().subscribe((r) => {

      this.apiService.storeInfo(r.store_id).subscribe((info: StoreInfo) => {
        // console.log(info.registration_step)
        if (intiial) {
          this.currentStep = info.registration_step
          if (info.registration_step === 3) this.fetchExpeditionList(true)
          this.addressForm.get('recipient').setValue(info.name)
          this.addressForm.get('recipient').disable()
        }
        if (!info.registration_step) this.router.navigate(["/dashboard"]);

        this.storeInfo = info
        this.imageUrl = info.image_url
        this.storeInfoForm.get('name').setValue(info.name)
        this.storeInfoForm.get('description').setValue(info.description)
        this.storeInfoForm.get('store_domain').setValue(this.parseStoreNameFromDomain(info.store_domain))
        this.onmarketWebsite = info.store_domain
        this.isLoading = false
        // console.log(this.storeInfo)
      },
        err => { console.error(err) })
    },
      e => { console.error(e) }
    )
  }

  domainChanged() {
    // console.log(this.storeInfoForm.value.store_domain)
    this.onmarketWebsite = this.apiService.ONMARKET_WEBSITE + '/store/store-profile?' + new HttpParams({ fromObject: { store_name: this.storeInfoForm.value.store_domain } }).toString()
  }

  handleFileInput(file: FileList) {
    console.log("HR");
    // Set File to Upload
    this.storeInfoData.set("image", file.item(0));

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(file.item(0));
  }

  logout() {
    // console.log(localStorage.getItem('jwt'));

    clearLocalStorage();
    this.loggedin = false;
    this.router.navigate(["login"]);
  }

  toggleNavbar() {
    this.isCollapsed = !this.isCollapsed;
  }

  submitStoreInfo() {
    if (!this.storeInfoForm.valid) return
    this.submitLoading = true
    this.storeInfoData.set("name", this.storeInfoForm.get("name").value);
    this.storeInfoData.set(
      "description",
      this.storeInfoForm.get("description").value
    );
    this.storeInfoData.set("store_domain", this.storeInfoForm.get("store_domain").value)

    this.apiService.updateSchedule(this.storeSchedule).subscribe(r => { }, err => { console.error(err) })

    this.apiService.editStore(this.storeInfoData, localStorage.getItem("jwt")).subscribe((r) => {
      this.apiService.updateRegistrationStep(2).subscribe((r) => {
        this.addressForm.get('recipient').setValue(this.storeInfoForm.get("name").value)
        this.addressForm.get('recipient').disable()
        this.currentStep = 2
        this.submitLoading = false
      }, e => { console.error(e) })

    },
      err => {
        this.submitLoading = false
        if (err.error.message === "Store_domain already exist") {
          Swal.fire({
            title: "Gagal mengubah info toko",
            text: "Domain Toko telah digunakan!",
            // html:'<p></p>',
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-warning",
            },
            icon: "warning",
          });
        }
        else if (err.error.message === "Name already exist") {
          Swal.fire({
            title: "Gagal mengubah info toko",
            text: "Nama Toko telah digunakan!",
            // html:'<p></p>',
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-warning",
            },
            icon: "warning",
          });
        }
        else {
          Swal.fire({
            title: "Error!",
            text: "Informasi tokomu gagal diubah !" + err.error.message,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-warning",
            },
            icon: "warning",
          });
        }
        console.error(err)
      })
  }


  useDefaultProfileImg() {
    this.imageUrl = "assets/img/store.png";
  }

  parseStoreNameFromDomain(storeDomain: string): string {
    const url = new URL(storeDomain)
    return decodeURIComponent(url.searchParams.get('store_name'))
  }

  back() {
    // console.log(this.currentStep)
    if (this.currentStep > 1)
      this.currentStep = this.currentStep - 1

    if (this.currentStep === 1) { this.addressForm.get('recipient').enable(); this.fetchStoreInfo(false) }
    if (this.currentStep === 2) { this.fetchStoreAddress() }
  }

  // === address ===

  formatPhoneNumber(event: any) {
    const inputValue = event.target.value;
    const phoneNumber = inputValue.toString().replace(/^0+/, '');

    // Check if the phone number already starts with '62'
    if (!phoneNumber.startsWith('62')) {
      this.addressForm.get('phone').setValue(`62${phoneNumber}`);
    }
  }

  updateMap(location = null) {
    const loader = new Loader({
      apiKey: this.MAPS_API_KEY,
    });

    loader.load().then(() => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode(location ? location : { address: this.temp_location }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          this.debounce_lat = location.lat()
          this.debounce_lng = location.lng()
          this.addressForm.get('lat').setValue(location.lat())
          this.addressForm.get('long').setValue(location.lng())

          const mapOptions = {
            center: { lat: location.lat(), lng: location.lng() },
            zoom: 15,
          };

          this.map = new google.maps.Map(document.getElementById("map"), mapOptions);

          const marker = new google.maps.Marker({
            position: mapOptions.center,
            map: this.map,
          });

          this.map.addListener('click', (event) => {
            const clickedLocation = {
              lat: event.latLng.lat(),
              lng: event.latLng.lng()
            }

            marker.setPosition(clickedLocation);
            this.debounce_lat = marker.getPosition().lat()
            this.debounce_lng = marker.getPosition().lng()

            this.addressForm.get('lat').setValue(marker.getPosition().lat())
            this.addressForm.get('long').setValue(marker.getPosition().lng())

            console.log(this.debounce_lat);
            console.log(this.debounce_lng);
          })
        } else {
          // console.log("Geocode was not successful for the following reason: " + status);
        }

      });
    })
  }

  submitAddress() {
    if (!this.addressForm.valid) return
    this.submitAddressLoading = true
    const payload: AddressData = {
      phone: this.addressForm.value.phone,
      province: this.address.province_name,
      city: this.address.city_name,
      district: this.address.sub_district_name,
      urban: this.address.urban_name,
      post_id: this.address.postal_code,
      address_input: this.addressForm.value.address_input,
      osas_log_id: this.address.id,
      lat: this.addressForm.value.lat,
      long: this.addressForm.value.long,
      address: {
        country_name: this.address.country_name,
        province_name: this.address.province_name,
        city_name: this.address.city_name,
        sub_district_name: this.address.sub_district_name,
        urban_name: this.address.urban_name,
        postal_code: this.address.postal_code,
        id: this.address.id,
        city_id: this.address.city_id,
      },
    }

    this.apiService.addAddress(payload).subscribe((r) => {
      this.apiService.updateRegistrationStep(3).subscribe((r) => {
        this.currentStep = 3
        this.submitAddressLoading = false
        this.fetchExpeditionList(true)
      }, err => { console.error(err) })
    },
      err => {
        console.error(err)
        this.submitAddressLoading = false

      })


  }

  private destination_serv(value: string): any {
    return this.apiService
      .destinationSearch(value)
      .pipe(catchError((err) => of([])));
  }

  displayFnDest(consignee?: any): string | undefined {
    if (typeof consignee === 'string') return consignee
    return consignee
      ? consignee.urban_name +
      ", " +
      consignee.sub_district_name +
      ", " +
      consignee.city_name +
      ", Prov. " +
      consignee.province_name +
      ", " +
      consignee.postal_code
      : undefined;
  }

  setAddress(value: any) {
    // console.log(value
    // )
    this.addressForm.get('dest').setValue(this.displayFnDest(value))
    this.address = value;
    this.temp_location = this.displayFnDest(value)
    this.updateMap()
    // console.log(value)
  }

  addressInputChange(e) {
    this.temp_location = e.target.value
    this.updateMap()
  }


  // === Expedition Setting === 
  createSeviceGroup(service: shippingMethod): FormGroup {
    return this.fb.group({
      service_id: service.service_id,
      service_name: service.service_name,
      max_weight: service.max_weight,
      expeditions: this.fb.array(service.expedition.map(exp => this.createExpeditionGroup(exp)))
    });
  }

  createExpeditionGroup(expedition): FormGroup {
    return this.fb.group({
      expedition_id: expedition.expedition_id,
      expedition_service_id: expedition.expedition_service_id,
      expedition_name: expedition.expedition_name,
      eta: expedition.eta,
      cod_available: expedition.cod_available,
      status: expedition.status,
      is_cod: { value: expedition.is_cod, disabled: !expedition.cod_available }
    });
  }

  get services(): FormArray {
    return this.shippingForm.get('services') as FormArray;
  }

  getExpeditions(serviceIndex: number): FormArray {
    return this.services.at(serviceIndex).get('expeditions') as FormArray;
  }


  fetchExpeditionList(loading: boolean) {
    this.isLoading = loading
    this.apiService.expeditionList().subscribe((r: shippingMethod[]) => {
      // console.log(r)
      this.expeditionData = r
      this.shippingForm = this.fb.group({
        services: this.fb.array(r.map(service => this.createSeviceGroup(service)))
      })
      this.isLoading = false
      // console.log(this.shippingForm)
    },
      err => {
        console.error(err.error.message)
        Swal.fire({
          title: "Error!",
          text: err.error.message,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-warning",
          },
          icon: "warning"
        })
      })
  }

  // updateExpedition($event, data, serviceId) {
  //   const payload: updateShippingMethod[] = [
  //     {
  //       status: $event.checked,
  //       service_id: serviceId,
  //       expedition_id: data.expedition_id,
  //       expedition_service_id: data.expedition_service_id,
  //       is_cod: false
  //     }
  //   ]
  //   // console.log(payload)
  //   this.apiService.updateExpedition(payload).subscribe(r => { this.fetchExpeditionList(false), err => { console.error(err); this.fetchExpeditionList(false) } })
  // }

  submitExpedition() {
    this.submitExpeditionLoading = true
    const payload: updateShippingMethod[] = this.shippingForm.value.services.flatMap(service =>
      service.expeditions.map(exp => ({
        service_id: service.service_id,
        expedition_id: exp.expedition_id,
        expedition_service_id: exp.expedition_service_id,
        status: exp.status,
        is_cod: exp.is_cod ? exp.is_cod : false
      }))
    );
    // console.log(payload)
    Swal.fire({
      title: "Simpan perubahan?",
      text:
        "Anda dapat mengubah info toko pada menu toko",
      icon: "info",
      showDenyButton: true,

      confirmButtonText: "Yes",
      denyButtonText: "No",
      confirmButtonColor: "#3085d6",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-3",
        denyButton: "order-2",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        this.apiService.updateExpedition(payload).subscribe(r => {
          this.apiService.updateRegistrationStep(0).subscribe((r) => {
            Swal.fire({
              title: "Sukses!",
              html: "<p>Selamat! Toko anda telah dibuka.<br/>Anda dapat menambahkan produk pada menu produk</p>",
              icon: "success"
            })
            this.router.navigate(["/dashboard"]);
          },
            e => { console.error(e) })

        })

      }
    })
  }
}
